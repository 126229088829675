@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

@layer base {
  html {
    font-family: 'Raleway', sans-serif;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.fade-anim img {
  animation: fadeAnim 0.5s ease-out;
}
@keyframes fadeAnim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
